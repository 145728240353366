import { defineStore } from 'pinia';
import { useAuth0, createAuth0 } from '@auth0/auth0-vue'
import moment from 'moment';

const handleRefreshTokenError = (error, auth0) => {
    auth0?.logout({ returnTo: '/login' });
    navigateTo({ path: '/login' });
}

export const useAuthenticationStore = defineStore('authentication', {
    persist: {
        storage: persistedState.localStorage,
    },
    state: () => ({
        accessToken: undefined,
        expireDate: undefined,
        loginDate: undefined,
        publicKey: undefined,
        userId: undefined,
        userEmail: undefined,
        picture: undefined,
    }),
    actions: {
        async refreshToken(auth0) {
            if (moment().diff(this.loginDate, 'minutes') > 15) {
                auth0.getAccessTokenSilently({
                    detailedResponse: true,
                    cacheMode: 'off'
                }).then((data) => {
                    this.setAuthTokens({
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        id_token: data.id_token,
                    });
                }).catch((error) => {
                    handleRefreshTokenError(error, auth0);
                });
            }
        },
        resetState() {
            this.picture = undefined;
            this.accessToken = undefined;
            this.expireDate = undefined;
            this.loginDate = undefined;
            this.publicKey = undefined;
            this.userId = undefined;
        },
        setUserPicture(picture) {
            this.picture = picture;
        },
        getUserId() {
            if (this.isUserLoggedIn()) {
                return this.userId;
            }
            throw createError('User is not logged in');
        },
        getUserEmail() {
            if (this.isUserLoggedIn()) {
                return this.userEmail;
            }
            throw createError('User is not logged in');
        },
        getPublicKey() {
            if (this.isUserLoggedIn()) {
                if (!this.publicKey || this.publicKey === 'undefined' || this.publicKey === 'null') {
                    return navigateTo('/generate-key');
                }
                return this.publicKey;
            }
            throw createError('User is not logged in');

        },
        isUserLoggedIn() {
            if (this.accessToken && this.userId) {
                return true;
            }
            return false;
        },
        setUserInfo({ userId, publicKey, userEmail, picture }) {
            this.userId = userId;
            this.publicKey = publicKey;
            this.userEmail = userEmail;
            this.picture = picture;
        },
        setAuthTokens({ access_token, expires_in }) {
            this.loginDate = moment();
            this.expireDate = moment().add(expires_in, 'seconds');
            this.accessToken = access_token;
        },
        setPublicKey(publicKey) {
            this.publicKey = publicKey;
        },
    }
});
