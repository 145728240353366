import { createAuth0 } from '@auth0/auth0-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const auth0 = createAuth0({
    domain: config.public.AUTH0_DOMAIN,
    clientId: config.public.AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    useRefreshTokensFallback: true,
    authorizationParams: {
      redirect_uri: config.public.AUTH0_REDIRECT_URI, 
      audience: config.public.AUTH0_TARGET_AUDIENCE,
      scope: 'openid profile email offline_access',
    }
  })
  nuxtApp.vueApp.use(auth0);
});