<template>
    <NuxtLayout name="404">
        <div class=" bg-secondary font-spaceGrotesk flex justify-center items-center h-screen text-center p-4">
            <div class="max-w-sm mx-auto">
                <img src="/assets/images/404.png" alt="Logo" class="w-56 mx-auto mb-8">
                <h1 class="text-4xl font-staatliches text-primary mb-4">PAGE NOT FOUND</h1>
                <p class="mb-8 font-spaceGrotesk font-bold text-black">
                    The requested resource is not available. Please return to the homepage or use our site navigation to find the correct page.
                </p>
                <nuxt-link href="/" class="btn-primary">Back to home</nuxt-link>
            </div>
        </div>
    </NuxtLayout>
</template>
<script setup>
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.font-staatliches {
  font-family: 'Staatliches', cursive;
}

.font-spaceGrotesk {
  font-family: 'Space Grotesk', sans-serif;
}
</style>