import { useAuthenticationStore } from "@/store/modules/authentication";
import jwt_decode from "jwt-decode";

export default defineNuxtRouteMiddleware((to, from) => {
    const allowedRoutesWithoutVerify = ['/login', '/verify-account', '/auth-success', '/logout'];
    const { accessToken } = useAuthenticationStore();
    if (accessToken && !allowedRoutesWithoutVerify.includes(to.path)) {
        const decoded_token = jwt_decode(accessToken);
        if (decoded_token) {
            const email = decoded_token['https://claims.myguardpass.com/email'] ? decoded_token['https://claims.myguardpass.com/email'] : undefined;
            const email_verified = decoded_token['https://claims.myguardpass.com/email_verified'] ? decoded_token['https://claims.myguardpass.com/email_verified'] : false;
            if (!email_verified) {
                const emailParam = email ? `email=${email}` : '';
                return navigateTo(`/verify-account?${emailParam}`);
            }
        }
    }
});