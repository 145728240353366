import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(nuxtApp => {
    const env = useRuntimeConfig().public.ENV;
    // If ENV is not staging or development return.
	// TODO: Allow only on Production environment after being tested properly.
    if (env !== 'production') return;
	// TODO: Remove hardcoded GTM ID
    const gtm = createGtm({
        id: 'GTM-MC7QFWKJ',
        defer: false,
        compatibility: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
    });
    nuxtApp.vueApp.use(gtm);
});
