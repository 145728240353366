import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45authenticated_45global from "/vercel/path0/middleware/0.authenticated.global.js";
import _1_45account_45verified_45global from "/vercel/path0/middleware/1.accountVerified.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45authenticated_45global,
  _1_45account_45verified_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}