<template>
    <!-- <VitePwaManifest /> -->
    <div>
        <NuxtLayout>
            <div>
                <LoadingIndicator></LoadingIndicator>
                <NuxtPage />
            </div>
        </NuxtLayout>
    </div>
</template>
<script setup>
import LoadingIndicator from '@/components/LoadingIndicator.ts'
import 'https://code.jquery.com/jquery-latest.min.js';
jQuery.ajax({
    url: "https://myguardpass.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-orl6br/b/4/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=es-ES&collectorId=97100111",
    type: "get",
    cache: true,
    dataType: "script"
});
</script>
<style>
#atlwdg-trigger {
    bottom: 0px !important;
    top:auto !important;
    left: 0px !important;
    right: auto !important;
    font-family: 'Space Grotesk', sans-serif !important;
}
</style>