import { default as auth_45successUakLZuuni3Meta } from "/vercel/path0/pages/auth-success.vue?macro=true";
import { default as create_45secret7CMwJg4gtqMeta } from "/vercel/path0/pages/create-secret.vue?macro=true";
import { default as edit_45secretqKtxYJ42ywMeta } from "/vercel/path0/pages/edit-secret.vue?macro=true";
import { default as invitation_45errorHmubyvv9E7Meta } from "/vercel/path0/pages/errors/invitation-error.vue?macro=true";
import { default as key_45error2T10rh5W3TMeta } from "/vercel/path0/pages/errors/key-error.vue?macro=true";
import { default as generate_45keyMAh7l79rWiMeta } from "/vercel/path0/pages/generate-key.vue?macro=true";
import { default as guardian_45invitationhyGqiILESfMeta } from "/vercel/path0/pages/guardian-invitation.vue?macro=true";
import { default as _91_91id_93_93M2i7qmJgJ7Meta } from "/vercel/path0/pages/guardians/[[id]].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_91id_93_93hxH61SAnSVMeta } from "/vercel/path0/pages/invitation/[[id]].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _91_91id_93_93gF9rQyUlkzMeta } from "/vercel/path0/pages/secrets/[[id]].vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as user_45profilepF1aS8aARoMeta } from "/vercel/path0/pages/user-profile.vue?macro=true";
import { default as verify_45accountp8jahxWm67Meta } from "/vercel/path0/pages/verify-account.vue?macro=true";
export default [
  {
    name: auth_45successUakLZuuni3Meta?.name ?? "auth-success",
    path: auth_45successUakLZuuni3Meta?.path ?? "/auth-success",
    meta: auth_45successUakLZuuni3Meta || {},
    alias: auth_45successUakLZuuni3Meta?.alias || [],
    redirect: auth_45successUakLZuuni3Meta?.redirect,
    component: () => import("/vercel/path0/pages/auth-success.vue").then(m => m.default || m)
  },
  {
    name: create_45secret7CMwJg4gtqMeta?.name ?? "create-secret",
    path: create_45secret7CMwJg4gtqMeta?.path ?? "/create-secret",
    meta: create_45secret7CMwJg4gtqMeta || {},
    alias: create_45secret7CMwJg4gtqMeta?.alias || [],
    redirect: create_45secret7CMwJg4gtqMeta?.redirect,
    component: () => import("/vercel/path0/pages/create-secret.vue").then(m => m.default || m)
  },
  {
    name: edit_45secretqKtxYJ42ywMeta?.name ?? "edit-secret",
    path: edit_45secretqKtxYJ42ywMeta?.path ?? "/edit-secret",
    meta: edit_45secretqKtxYJ42ywMeta || {},
    alias: edit_45secretqKtxYJ42ywMeta?.alias || [],
    redirect: edit_45secretqKtxYJ42ywMeta?.redirect,
    component: () => import("/vercel/path0/pages/edit-secret.vue").then(m => m.default || m)
  },
  {
    name: invitation_45errorHmubyvv9E7Meta?.name ?? "errors-invitation-error",
    path: invitation_45errorHmubyvv9E7Meta?.path ?? "/errors/invitation-error",
    meta: invitation_45errorHmubyvv9E7Meta || {},
    alias: invitation_45errorHmubyvv9E7Meta?.alias || [],
    redirect: invitation_45errorHmubyvv9E7Meta?.redirect,
    component: () => import("/vercel/path0/pages/errors/invitation-error.vue").then(m => m.default || m)
  },
  {
    name: key_45error2T10rh5W3TMeta?.name ?? "errors-key-error",
    path: key_45error2T10rh5W3TMeta?.path ?? "/errors/key-error",
    meta: key_45error2T10rh5W3TMeta || {},
    alias: key_45error2T10rh5W3TMeta?.alias || [],
    redirect: key_45error2T10rh5W3TMeta?.redirect,
    component: () => import("/vercel/path0/pages/errors/key-error.vue").then(m => m.default || m)
  },
  {
    name: generate_45keyMAh7l79rWiMeta?.name ?? "generate-key",
    path: generate_45keyMAh7l79rWiMeta?.path ?? "/generate-key",
    meta: generate_45keyMAh7l79rWiMeta || {},
    alias: generate_45keyMAh7l79rWiMeta?.alias || [],
    redirect: generate_45keyMAh7l79rWiMeta?.redirect,
    component: () => import("/vercel/path0/pages/generate-key.vue").then(m => m.default || m)
  },
  {
    name: guardian_45invitationhyGqiILESfMeta?.name ?? "guardian-invitation",
    path: guardian_45invitationhyGqiILESfMeta?.path ?? "/guardian-invitation",
    meta: guardian_45invitationhyGqiILESfMeta || {},
    alias: guardian_45invitationhyGqiILESfMeta?.alias || [],
    redirect: guardian_45invitationhyGqiILESfMeta?.redirect,
    component: () => import("/vercel/path0/pages/guardian-invitation.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93M2i7qmJgJ7Meta?.name ?? "guardians-id",
    path: _91_91id_93_93M2i7qmJgJ7Meta?.path ?? "/guardians/:id?",
    meta: _91_91id_93_93M2i7qmJgJ7Meta || {},
    alias: _91_91id_93_93M2i7qmJgJ7Meta?.alias || [],
    redirect: _91_91id_93_93M2i7qmJgJ7Meta?.redirect,
    component: () => import("/vercel/path0/pages/guardians/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93hxH61SAnSVMeta?.name ?? "invitation-id",
    path: _91_91id_93_93hxH61SAnSVMeta?.path ?? "/invitation/:id?",
    meta: _91_91id_93_93hxH61SAnSVMeta || {},
    alias: _91_91id_93_93hxH61SAnSVMeta?.alias || [],
    redirect: _91_91id_93_93hxH61SAnSVMeta?.redirect,
    component: () => import("/vercel/path0/pages/invitation/[[id]].vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout",
    path: logoutBqDDZXgXsyMeta?.path ?? "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93gF9rQyUlkzMeta?.name ?? "secrets-id",
    path: _91_91id_93_93gF9rQyUlkzMeta?.path ?? "/secrets/:id?",
    meta: _91_91id_93_93gF9rQyUlkzMeta || {},
    alias: _91_91id_93_93gF9rQyUlkzMeta?.alias || [],
    redirect: _91_91id_93_93gF9rQyUlkzMeta?.redirect,
    component: () => import("/vercel/path0/pages/secrets/[[id]].vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: user_45profilepF1aS8aARoMeta?.name ?? "user-profile",
    path: user_45profilepF1aS8aARoMeta?.path ?? "/user-profile",
    meta: user_45profilepF1aS8aARoMeta || {},
    alias: user_45profilepF1aS8aARoMeta?.alias || [],
    redirect: user_45profilepF1aS8aARoMeta?.redirect,
    component: () => import("/vercel/path0/pages/user-profile.vue").then(m => m.default || m)
  },
  {
    name: verify_45accountp8jahxWm67Meta?.name ?? "verify-account",
    path: verify_45accountp8jahxWm67Meta?.path ?? "/verify-account",
    meta: verify_45accountp8jahxWm67Meta || {},
    alias: verify_45accountp8jahxWm67Meta?.alias || [],
    redirect: verify_45accountp8jahxWm67Meta?.redirect,
    component: () => import("/vercel/path0/pages/verify-account.vue").then(m => m.default || m)
  }
]