import { defineStore } from 'pinia';

export const useQueryParamsStore = defineStore('queryParams', {
    persist: {
        storage: persistedState.localStorage,
    },
    state: () => ({
        secretId: false,
        referral: false,
    }),
    actions: {
        setSecretId(secretId) {
            this.secretId = secretId;
        },
        resetSecretId() {
            this.secretId = false;
        },
        async setReferral(referral) {
            this.referral = referral;
        },
        resetReferral() {
            this.referral = false;
        },
    },
});