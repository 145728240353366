import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();
  if (!config.public.SENTRY.DSN) {
    return
  }
  if (process.env.NODE_ENV === "development") {
    return
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.SENTRY.DSN,
    environment: config.public.SENTRY.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Configure this whole part as you need it!
    tracesSampleRate: config.public.SENTRY.tracesSampleRate, // Change in prod
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})