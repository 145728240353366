import { useAuthenticationStore } from '@/store/modules/authentication';
import { useQueryParamsStore } from '@/store/modules/queryParams';
import { useAuth0 } from '@auth0/auth0-vue';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const auth0 = await useAuth0();
    const authenticationStore = useAuthenticationStore();
    const queryParamsStore = useQueryParamsStore();
    if (to.query && to.query.referral) {
        await queryParamsStore.setReferral(to.query.referral);
    }
    const allowedRoutesWithoutAuth = ['/login', '/register', '/verify-account', '/auth-success', '/logout', '/signup'];
    const disallowedRoutesWithoutPublicKey = ['/create-secret', '/edit-secret'];

    const authToken = authenticationStore.accessToken;

    const publicKey = authenticationStore.publicKey;

    // Intentar refrescar el token cuando se accede a una ruta autenticada.
    if (!allowedRoutesWithoutAuth.includes(to.path)) {
        authenticationStore.refreshToken(auth0);
    }

    // Si authToken no existe o es undefined
    if (!authToken && !allowedRoutesWithoutAuth.includes(to.path)) {
        return navigateTo('/auth-success');
    }

    // Si authToken es válido pero publicKey no existe o es 'undefined'
    if (authToken && (!publicKey || publicKey === 'undefined' || publicKey == 'null') && disallowedRoutesWithoutPublicKey.includes(to.path)) {
        return navigateTo('/generate-key');
    }
});
