import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import vue_query_wrmMkNpEpe from "/vercel/path0/plugins/vue-query.ts";
import auth0_ZnwwTmFbx2 from "/vercel/path0/plugins/auth0.ts";
import gtm_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
import mitt_event_bus_LbQ9A4wOim from "/vercel/path0/plugins/mitt-event-bus.ts";
import page_loading_nXSylFVZsZ from "/vercel/path0/plugins/page-loading.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_final_modal_pML93k5qcp from "/vercel/path0/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  vue_query_wrmMkNpEpe,
  auth0_ZnwwTmFbx2,
  gtm_MVzSxF8h1r,
  mitt_event_bus_LbQ9A4wOim,
  page_loading_nXSylFVZsZ,
  sentry_client_GoGQuZo4Et,
  vue_final_modal_pML93k5qcp
]